import React from "react"
import {
    Split,
    Content,
    Heading,
    Copy,
    Cover,
    CoverImg,
} from "../../pages/activities"
import useInView from "react-cool-inview"

const Breathe = ({ setCurrentSection, yoga, spa, image }) => {
    const { ref } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.5,
        onEnter: () => {
            setCurrentSection(`#breathe`)
        },
    })

    return (
        <Split id="breathe" ref={ref}>
            <Content style={{ order: 2 }}>
                <Heading>Yoga</Heading>
                <Copy>{yoga}</Copy>
                <Heading>Spa</Heading>
                <Copy>{spa}</Copy>
            </Content>
            <Cover style={{ marginBottom: 50 }}>
                <CoverImg src={image} />
            </Cover>
        </Split>
    )
}

export default Breathe
