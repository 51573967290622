import React from "react"
import { Split, Content, Heading, Copy } from "../../pages/activities"
import useInView from "react-cool-inview"

const ExperienceTobago = ({ setCurrentSection }) => {
    const { ref } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.5,
        onEnter: () => {
            setCurrentSection(`#experience`)
        },
    })

    return (
        <Split
            style={{ padding: "5vh 20px", display: "block" }}
            id="experience"
            ref={ref}
        >
            <Content style={{ alignItems: "center" }}>
                <Heading style={{ textAlign: "center" }}>
                    No Overcrowded Beaches. No Tourist Traps
                </Heading>
                <Copy style={{ maxWidth: 650, textAlign: "center" }}>
                    What sets Tobago apart? Tucked away in the Southern
                    Caribbean, this gem of an island offers an expansive and
                    unique travel experience. In addition to a number of
                    accessible, beautiful sandy beaches that remain verdant and
                    unspoiled, Tobago is home to the oldest protected forest in
                    the western hemisphere – just one of the reasons that nature
                    seekers and bird watchers have this island on their to-do
                    lists. Surfing is excellent here as well, notably in peak
                    season, December to March. We have top-notch diving spots
                    and reefs, where you can experience nature in relative
                    solace. Or, if you’re looking for sporting events (ever been
                    to a goat race?) or culture, Tobago is rich with it.
                </Copy>
            </Content>
        </Split>
    )
}

export default ExperienceTobago
