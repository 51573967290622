import React from "react"
import styled from "styled-components"
import { MaxWidthContainer } from "./layout"

const Container = styled(MaxWidthContainer)`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 30px;
    gap: 30px;
    margin-bottom: 12vh;
    width: 100%;
    padding: 15px;

    @media (max-width: 550px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }
`
const Image = styled.img`
    margin: 0;
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
`

const FooterGallery = ({ gallery }) => {
    return (
        <Container>
            {gallery.map((image, index) => (
                <Image key={index} src={image.sourceUrl} />
            ))}
        </Container>
    )
}

export default FooterGallery
