import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Flex } from "./reusables"
import { useRecoilValue } from "recoil"
import { headerHeight } from "../state/atoms"

const Container = styled(Flex)`
    padding: 0 30px;
    position: sticky;
    top: ${props => props.height}px;
    background: ${colors.base};
    width: 100%;
    z-index: 98;
    color: white;
`
const MenuList = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: scroll;

    @media (max-width: 980px) {
        justify-content: flex-start;
    }
    //Hide scroll bars
    //Firefox
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
`
const MenuItem = styled.button`
    background: ${props => (props.selected ? colors.primary : "none")};
    outline: none;
    text-transform: uppercase;
    color: white;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    padding: 15px 25px;
    margin-right: 20px;
    font-size: 15px;
    letter-spacing: 1.6px;
    transition: 0.2s ease;
`

const Subnavigation = ({ menu, currentSection }) => {
    const currentHeaderHeight = useRecoilValue(headerHeight)

    return (
        <Container height={currentHeaderHeight} justify="center">
            <MenuList>
                {menu.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            scrollTo(item.anchor)
                        }}
                        selected={currentSection === item.anchor}
                    >
                        {item.title}
                    </MenuItem>
                ))}
            </MenuList>
        </Container>
    )
}

export default Subnavigation
