import React, { useState } from "react"
import styled from "styled-components"
import { FullSplit, Title, Copy, Cover, CoverImg } from "../../pages/activities"
import { MainButton } from "../../styles/buttons"
import useInView from "react-cool-inview"
import DayPassRates from "./day-pass-rates"

const DayPass = ({ setCurrentSection, title, copy, image }) => {
    const [showingDayPass, setShowingDayPass] = useState(false)

    const { ref } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.5,
        onEnter: () => {
            setCurrentSection(`#daypass`)
        },
    })

    return (
        <FullSplit style={{ height: "auto" }} ref={ref} id="daypass">
            <DayPassContent
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    flex: 1,
                    // marginBottom: 10,
                }}
            >
                <Title>{title}</Title>
                <Copy>{copy}</Copy>
                <MainButton onClick={() => setShowingDayPass(!showingDayPass)}>
                    {showingDayPass ? "Close" : "View"} Day Pass Rates
                </MainButton>
            </DayPassContent>
            <Cover
                style={{
                    padding: showingDayPass ? "20px" : "0px",
                    height: "100%",
                }}
            >
                {showingDayPass ? (
                    <DayPassRates />
                ) : (
                    <CoverImg
                        style={{
                            height: "100%",
                            minHeight: "60vh",
                            objectFit: "contain",
                            margin: 0,
                        }}
                        src={image}
                    />
                )}
            </Cover>
        </FullSplit>
    )
}

export default DayPass

const DayPassContent = styled.div`
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding: 20px;
        margin-bottom: 50px;
    }
`
