import React from "react"
import {
    Split,
    Content,
    Heading,
    Copy,
    Cover,
    CoverImg,
    Title,
} from "../../pages/activities"
import { MainButton } from "../../styles/buttons"
import useInView from "react-cool-inview"
import { colors } from "../../styles/colors"

const Immerse = ({ setCurrentSection, title, copy, image }) => {
    const { ref, inView } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.5,
        onEnter: () => {
            setCurrentSection(`#immerse`)
        },
    })

    return (
        <div id="immerse" ref={ref} style={{ backgroundColor: colors.base }}>
            <Split style={{ color: "white" }}>
                <Cover style={{ marginBottom: 50 }}>
                    <CoverImg src={image} />
                </Cover>
                <Content>
                    <Title>{title}</Title>
                    <Copy>{copy}</Copy>
                </Content>
            </Split>
        </div>
    )
}

export default Immerse
