import React, { useState } from "react"
import styled from "styled-components"
import Layout, { MaxWidthContainer } from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import Subnavigation from "../components/subnavigation"
import TitleBlock from "../components/title-block"
import { colors } from "../styles/colors"
import useInView from "react-cool-inview"
import Breathe from "../components/activities/breathe"
import Immerse from "../components/activities/immerse"
import ExperienceTobago from "../components/activities/experience-tobago"
import DayPass from "../components/activities/day-pass"
import AwesomeSlider from "react-awesome-slider"
import "react-awesome-slider/dist/styles.css"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import { graphql } from "gatsby"
import FooterGallery from "../components/footer-gallery"

const AutoplaySlider = withAutoplay(AwesomeSlider)

const Container = styled.div``
export const Split = styled(MaxWidthContainer)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    width: 100%;
    padding: 10vh 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 6vh 20px;
        column-gap: 0;
    }
`
export const FullSplit = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    background: ${colors.base};
    color: white;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
        padding: 7vh 20px;
        column-gap: 0;
    }
`
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
export const Heading = styled.h5`
    font-size: 18px;
    margin-bottom: 15px;
`
export const Copy = styled.p`
    max-width: 500px;
    line-height: 1.4;
`
export const Cover = styled.div`
    /* height: 100%; */
    width: 100%;
    position: relative;
`
export const CoverImg = styled.img`
    margin: 0;
    width: 100%;
    height: 100%;
`
export const Title = styled.h2`
    text-transform: uppercase;
    font-size: 50px;
    letter-spacing: 1px;
    width: 100%;

    @media (max-width: 550px) {
        font-size: 35px;
        /* margin-top: 40px; */
    }
`
const FullCoverContainer = styled.div`
    width: 100%;
    height: 80vh;
    /* height: 100%; */
    background: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    @media (min-width: 650px) {
        padding-top: 0;
    }
    div > div > div > div > div > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    div > div > div > ul > li {
        margin-bottom: 0;
    }
`
const Slide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    width: 100%;
    /* height: 100vh; */
    cursor: pointer;
`

const ActivitiesPage = ({ data }) => {
    const pageData = data.wpPage.Activities
    const {
        title,
        yoga,
        spa,
        spaHeader,
        immerseTitle,
        immerseCopy,
        immerseImage,
        experienceGallery,
        diveInGallery,
        diveInTitle,
        diveInCopy,
        dayPassImage,
        dayPassTitle,
        dayPassCopy,
        entertainmentImage,
        entertainmentTitle,
        entertainmentCopy,
        footerGallery,
    } = pageData

    //State
    const [currentSection, setCurrentSection] = useState("#breathe")
    const { ref } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.5,
        onEnter: () => {
            console.log(ref.current)
            setCurrentSection(`#experience`)
        },
    })

    return (
        <Layout>
            <SEO title="Activities" />
            <PageHeader title="Activities" />
            <Subnavigation currentSection={currentSection} menu={menu} />
            <TitleBlock title={title} />
            <Container>
                <Breathe
                    yoga={yoga}
                    spa={spa}
                    image={spaHeader.sourceUrl}
                    setCurrentSection={setCurrentSection}
                />
                <Immerse
                    title={immerseTitle}
                    copy={immerseCopy}
                    image={immerseImage.sourceUrl}
                    setCurrentSection={setCurrentSection}
                />

                <FullCoverContainer>
                    <AutoplaySlider
                        bullets={true}
                        organicArrows={true}
                        fillParent
                        infinite
                        play={true}
                        cancelOnInteraction={false} // should stop playing on user interaction
                        interval={6000}
                    >
                        {experienceGallery.map((el, index) => (
                            <Slide key={index} data-src={el.sourceUrl} />
                        ))}
                    </AutoplaySlider>
                </FullCoverContainer>

                <TitleBlock title="Experience Tobago" />

                <ExperienceTobago setCurrentSection={setCurrentSection} />

                <FullCoverContainer>
                    <AutoplaySlider
                        bullets={true}
                        organicArrows={true}
                        fillParent
                        infinite
                        play={true}
                        cancelOnInteraction={false} // should stop playing on user interaction
                        interval={6000}
                    >
                        {diveInGallery.map((el, index) => (
                            <Slide key={index} data-src={el.sourceUrl} />
                        ))}
                    </AutoplaySlider>
                </FullCoverContainer>
                <TitleBlock title={diveInTitle} copy={diveInCopy} />

                <DayPass
                    title={dayPassTitle}
                    copy={dayPassCopy}
                    image={dayPassImage.sourceUrl}
                    setCurrentSection={setCurrentSection}
                />

                <Split>
                    <Content>
                        <Title>{entertainmentTitle}</Title>
                        <Copy>{entertainmentCopy}</Copy>
                    </Content>
                    <Cover>
                        <CoverImg src={entertainmentImage.sourceUrl} />
                    </Cover>
                </Split>
                {footerGallery && footerGallery.length > 0 && (
                    <FooterGallery gallery={footerGallery} />
                )}
            </Container>
        </Layout>
    )
}

export default ActivitiesPage

export const ActivitiesQuery = graphql`
    query activitiesQuery {
        wpPage(title: { eq: "Activities" }) {
            Activities {
                dayPassCopy
                dayPassTitle
                diveInCopy
                diveInTitle
                immerseCopy
                immerseTitle
                title
                spa
                yoga
                spaHeader {
                    sourceUrl
                }
                dayPassImage {
                    sourceUrl
                }
                immerseImage {
                    sourceUrl
                }
                experienceGallery {
                    sourceUrl
                }
                diveInGallery {
                    sourceUrl
                }
                entertainmentImage {
                    sourceUrl
                }
                entertainmentTitle
                entertainmentCopy
                footerGallery {
                    sourceUrl
                }
            }
        }
    }
`

const menu = [
    {
        title: "Breathe In New Life",
        anchor: "#breathe",
    },
    {
        title: "Immerse Yourself",
        anchor: "#immerse",
    },
    {
        title: "Experience Tobago",
        anchor: "#experience",
    },
    {
        title: "Day Pass",
        anchor: "#daypass",
    },
]
