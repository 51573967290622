import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"
import { colors } from "../../styles/colors"

const Table = styled(motion.table)`
    font-family: "komet";
    font-size: 16px;
    margin: 0;

    @media (max-width: 700px) {
        order: 2;
    }
    @media (max-width: 550px) {
        font-size: 14px;
    }
`
const TDetails = styled.td`
    text-transform: ${props => (props.title ? "uppercase" : "none")};
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    font-weight: ${props => (props.title ? 700 : 300)};
    color: ${props => (props.title ? colors.primary : "white")};
`

const DayPassRates = () => {
    return (
        <Table
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <thead>
                <tr>
                    <TDetails title>Day Pass</TDetails>
                    <TDetails title>Cost (TTD) Per Person</TDetails>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <TDetails title></TDetails>
                </tr>
                <tr>
                    <TDetails>Mot Mot Pass</TDetails>
                    <TDetails>TT$476</TDetails>
                </tr>
                <tr>
                    <TDetails>10am - 6pm</TDetails>
                    <TDetails></TDetails>
                </tr>
                <tr>
                    <TDetails></TDetails>
                    <TDetails></TDetails>
                </tr>
                <tr>
                    <TDetails title></TDetails>
                </tr>
                <tr>
                    <TDetails>Hummingbird Pass</TDetails>
                    <TDetails>TT$816</TDetails>
                </tr>
                <tr>
                    <TDetails>7am - 6pm</TDetails>
                    <TDetails></TDetails>
                </tr>
                <tr>
                    <TDetails></TDetails>
                    <TDetails></TDetails>
                </tr>
                {/* NEW ROW */}
                <tr>
                    <TDetails title></TDetails>
                </tr>
                <tr>
                    <TDetails>Jacaranda Pass</TDetails>
                    <TDetails>TT$1122</TDetails>
                </tr>
                <tr>
                    <TDetails>10am - 6pm</TDetails>
                    <TDetails></TDetails>
                </tr>
                <tr>
                    <TDetails></TDetails>
                    <TDetails></TDetails>
                </tr>

                {/* NEW ROW */}
                <tr>
                    <TDetails title></TDetails>
                </tr>
                <tr>
                    <TDetails>Golf Pass</TDetails>
                    <TDetails>TT$782</TDetails>
                </tr>
                <tr>
                    <TDetails>7am - 6pm</TDetails>
                    <TDetails></TDetails>
                </tr>
                <tr>
                    <TDetails></TDetails>
                    <TDetails></TDetails>
                </tr>
            </tbody>
        </Table>
    )
}

export default DayPassRates
