import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import { Flex } from "./reusables"

const Container = styled(Flex)`
    text-align: center;
    padding: 12vh 20px;

    @media (max-width: 768px) {
        padding: 7vh 20px;
    }
`
const Title = styled.h2`
    color: ${colors.copy};
    text-transform: uppercase;
    margin-bottom: ${props => (props.hasDescription ? "60px" : "0px")};
    font-size: 55px;

    @media (max-width: 550px) {
        font-size: 30px;
    }
`
const Copy = styled.p`
    color: ${colors.copy};
    font-size: 18px;
    max-width: 750px;
    margin: 0 auto;
    line-height: 1.4;

    @media (max-width: 550px) {
        font-size: 16px;
    }
`

const TitleBlock = ({ title, copy }) => {
    return (
        <Container direction="column" align="center">
            <Title hasDescription={copy}>{title}</Title>
            <Copy>{copy}</Copy>
        </Container>
    )
}

export default TitleBlock
